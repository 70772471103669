import {
    isWishlistEnabled,
    WishlistDispatcher as SourceWishlistDispatcher,
} from 'SourceStore/Wishlist/Wishlist.dispatcher';

import WishlistQuery from 'Query/Wishlist.query';
import { Wishlist } from 'Query/Wishlist.type';
import CartDispatcher from 'Store/Cart/Cart.dispatcher';
import { showNotification } from 'Store/Notification/Notification.action';
import { NotificationType, ShowNotificationAction } from 'Store/Notification/Notification.type';
import {
    clearWishlist,
    removeItemFromWishlist,
    updateAllProductsInWishlist,
    updateIsLoading,
} from 'Store/Wishlist/Wishlist.action';
import { NetworkError } from 'Type/Common.type';
import { GQLWishlistItemInput, GQLWishlistItemUpdateInput } from 'Type/Graphql.type';
import { isSignedIn } from 'Util/Auth/IsSignedIn';
import { getAuthorizationToken } from 'Util/Auth/Token';
import { getErrorMessage } from 'Util/Request/Error';
import { fetchMutation } from 'Util/Request/Mutation';
import { fetchQuery } from 'Util/Request/Query';
import { getStoreState } from 'Util/Store';
import { getPriceRange } from 'Util/Wishlist';
import agtm from './../../gtm/tags';

export {
    isWishlistEnabled,
};

/** @namespace AlmusbahblendPwa/Store/Wishlist/Dispatcher */
export class WishlistDispatcher extends SourceWishlistDispatcher {
    // TODO implement logic

    async addItemToWishlist(
        dispatch,
        options,
    ) {
        if (!isSignedIn()) {
            return;
        }

        try {
            const { items = [], wishlistId = '' } = options;

            dispatch(updateIsLoading(true));
            const {
                addProductsToWishlist: { user_errors },
            } = await fetchMutation(WishlistQuery.addProductsToWishlist(wishlistId, items));

            // console.log("add Whislist options: ", options);

            if (user_errors.length > 0) {
                user_errors.map(({ message }) => dispatch(
                    showNotification(
                        NotificationType.ERROR,
                        __('We can`t add the item to Wishlist right now: %s', message).toString(),
                    ),
                ));
            } else {
                dispatch(showNotification(NotificationType.SUCCESS, __('Product added to wish-list!')));
                await this._syncWishlistWithBE(dispatch);
            }
        } catch {
            dispatch(showNotification(NotificationType.ERROR, __('Error updating wish list!')));
        } finally {
            dispatch(updateIsLoading(false));
        }
    }

    async removeItemFromWishlist(
        dispatch,
        { item_id, noMessages },
    ) {
        if (!item_id || !isSignedIn()) {
            return Promise.reject();
        }

        dispatch(updateIsLoading(true));

        try {
            await fetchMutation(WishlistQuery.getRemoveProductFromWishlistMutation(item_id));
        } catch (e) {
            if (!noMessages) {
                dispatch(showNotification(NotificationType.ERROR, __('Error updating wish list!')));
            }

            return Promise.reject();
        }

        dispatch(removeItemFromWishlist(item_id));

        if (!noMessages) {
            dispatch(showNotification(NotificationType.SUCCESS, __('Product has been removed from your Wish List!')));
        }

        return Promise.resolve();
    }
}

export default new WishlistDispatcher();
